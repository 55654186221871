/**
 * 共同模块js
 */
import axios from "@/resources/js/request";
const request = axios.request;
/**
 * 获取帮助菜单
 */
export const getHelpMenu = () => {
  return request.doGet('/common/helpMenu');
}
/**
 * 获取帮助问题
 */
export const getHelpProblem = ({typeId, content}) => {
  return request.doGet('/common/getHelpProblem', {
    typeId: typeId,
    content: content,
  });
}
